<template>
    <section>
        <InsideHeader :title="pageData.page_heading" :bredcrumb="breadCrumb" />
        <div class="section login-2 py-5">
            <div class="container">                
                <div class="row align-items-center">
                    <div class="col-md-6 cform_left">
                        <div class="form-section">                        
                            <div class="heading_main text_align_left">
                                <h2 v-html="pageHeading[0].title"></h2>
                            </div>
                            <ContactForm/>
                        </div>
                    </div>

                    <div class="col-sm-6 cinfo_right">
                        <div class="contact_info clearfix">
                            <div class="footer_blog full">                       
                                <ul class="full">
                                    <li><strong><i class="uil uil-location-pin-alt"></i></strong><span class="address" v-html="siteAddress"></span></li>
                                    <li><strong><i class="uil uil-envelope"></i></strong><span><a class="email_a" :href="'mailto:'+siteEmail">{{ siteEmail }}</a></span></li>
                                    <li><strong><i class="uil uil-phone-volume"></i></strong><span>{{ sitePhone }}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>  
</template>

<style scoped>
.footer_blog.full ul li .email_a{color: #000 !important;}
</style>

<script>
import InsideHeader from "../components/InsideHeader.vue";
import ContactForm from "../components/Contact/Form";
import Translation from '../../public/translation.json'

export default {
    components: {
        InsideHeader, 
        ContactForm
    },
    data(){
        return {
            breadcrumb: [],
            dir_path: '',
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            pageData: [],
            heading: [
                {lang: 'en', title: `<span>Get in </span> touch`},
                {lang: 'ar', title: `ابقى على تواصل`},
                {lang: 'fr', title: `<span>Entrer en</span> contact`}
            ],
        }
    },   
    methods: {
        pageContent(field){
            return Translation.content[this.defaultLang][field]
        },
        getPageData() {
            let slug = 'contact-us'
            this.$store.dispatch('getPageData', slug).then(res=>{
               if(res.data.status === true) {
                    this.pageData = res.data.data
                    if(res.data.dir_path) {
                        this.dir_path = res.data.dir_path
                    }
                }
            })
            .catch(err => {
                console.log(err)
                this.$router.push({name: '404'})
            })
        },
        getData(){            
            this.$http.get(`/contact-us`).then(res=>{                       
                if(res.status === 200 && res.data){
                    if(res.data.page_data)
                        this.pageData = res.data.page_data
                    if(res.data.file_path)
                        this.filePath = res.data.file_path
                }
            })
        }
    },    
    computed: {
        breadCrumb(){
            return this.breadcrumb = [
                {title: this.pageContent('home'), path:'/'},
                {title: this.pageContent('forum'), path:'#'}
            ]
        },
        pageHeading(){
            return this.heading.filter(item=> {
                return (item.lang === this.defaultLang) ? item : ''
            })
        },
        siteEmail(){
            return  this.$store.getters.siteEmail
        },
        sitePhone(){
            return  this.$store.getters.sitePhone
        },
        siteAddress(){
            return this.$store.getters.siteAddress
        },
        tagline(){
            return this.$store.state.configuration.tagline
        }
    },
    created: function(){
        this.getPageData()
       //console.log('beforCreate hook has been called');
    },
}
</script>

<style scoped>
.address{text-align: left;}
</style>