<template>
    <form action="" method="POST" id="contact-frm" @submit.prevent="contactFormSubmit">
        <div class="form-group form-box" id="name_block">
            <input type="text" :placeholder="placeholderText('name')" name="name" v-model.trim="$v.formData.name.$model">
            <i class="icon-feather-user"></i>
            <p class="text-danger form-error" v-if="!formStatus && $v.formData.name.$error && !$v.formData.name.required">{{ validationRequired('name.required') }}</p>
            <p class="text-danger form-error" v-if="!formStatus && $v.formData.name.$error && !$v.formData.name.minLength">{{ validationRequired('name.invalid') }}</p>
        </div>
        <div class="form-group form-box" id="email_block">
            <input type="email" :placeholder="placeholderText('email')" name="email"  maxlength="50" v-model.trim="$v.formData.email.$model">
            <i class="icon-material-baseline-mail-outline"></i>
            <p class="form-error text-danger" v-if="!formStatus && $v.formData.email.$error && !$v.formData.email.required">{{ validationRequired('email.required') }}</p>
            <p class="form-error text-danger" v-if="!formStatus && $v.formData.email.$error && !$v.formData.email.email">{{ validationRequired('email.invalid') }}</p>
        </div>
        <div class="form-group form-box" id="phone_block">
            <input type="tel" :placeholder="placeholderText('phone')" name="phone"  v-model.trim="$v.formData.phone.$model"  onkeyup="javascript: this.value = this.value.replace(/[^0-9]/g, '');">
            <i class="icon-feather-phone"></i>
            <p class="form-error text-danger" v-if="!formStatus && $v.formData.phone.$error && !$v.formData.phone.required">{{ validationRequired('phone.required') }}</p>
            <p class="form-error text-danger" v-if="!formStatus && $v.formData.phone.$error && !$v.formData.phone.numeric">{{ validationRequired('phone.invalid') }}</p>
        </div>
        <div class="form-group form-box" id="subject_block">
            <input type="text" :placeholder="placeholderText('subject')" name="subject" v-model.trim="$v.formData.subject.$model" >
            <i class="icon-material-outline-textsms"></i>
            <p class="text-danger form-error" v-if="!formStatus && $v.formData.subject.$error && !$v.formData.subject.required">{{ validationRequired('subject.required') }}</p>
        </div>
        <div class="form-group form-box" id="message_block">
            <textarea name="message" :placeholder="placeholderText('message')" v-model.trim="$v.formData.message.$model" ></textarea>
            <i class="icon-feather-message-square"></i>
            <p class="text-danger form-error" v-if="!formStatus && $v.formData.message.$error && !$v.formData.message.required">{{ validationRequired('message.required') }}</p>
        </div>
        <div class="form-group mb-0 clearfix">
            <button type="submit" class="btn-md gradient-btn btn-theme float-left" :data-title="button">{{ button }}</button>
        </div>  
        <div class="resMssg text-center mt-3" v-html="formResponse"></div>                             
    </form>
</template>

<script>
import { required, minLength, email, numeric } from 'vuelidate/lib/validators'
import Translation from '../../../public/translation.json'

export default {
    data() {
        return {
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            formResponse: '',
            formStatus: true,
            formData: {
                name: '',
                email: '',
                subject: '',
                phone: '',
                message: ''
            }
        }
    },
    computed: {
        button() {
            return Translation.button[this.defaultLang].submit
        }
    },
    validations: {
        formData: {
            name: {
                required,
                minLength: minLength(4)
            },
            email: {required,email},
            phone: {required, numeric},
            subject:{required},
            message: {required}
        }
    },
    methods: {
        placeholderText(field){
            return Translation.placeholder[this.defaultLang][field]
        },
        validationRequired(field){
            return Translation.validation[this.defaultLang][field]
        },
        contactFormSubmit(){
            this.formStatus = false
            this.$v.$touch()
            if(!this.$v.$invalid){
                document.querySelector('.resMssg').style.display = 'block'
                this.formResponse = ''
                this.$http.post(`/contact`, this.formData )
                .then(res=>{
                    if(typeof res !== 'undefined' && res.status === 200){
                         if(res.data.status === true){
                            this.resetForm()
                            this.formResponse =`<p class="alert alert-success">${res.data.message}</p>`    
                         }                
                        else
                            this.formResponse =`<p class="alert alert-danger">${res.data.error}</p>`
                    }
                    
                }).catch(err => {
                    console.log('err', err);
                    this.formResponse =`<p class="alert alert-danger">Something Went Wrong!</p>`
                })

                this.togellingResponse();
            }
        },
        togellingResponse(){
            setTimeout(()=>{
                document.querySelector('.resMssg').style.display = 'none'
            }, 5000)
        },        
        resetForm(){
            Object.keys(this.formData).forEach(item=>{
                this.formData[item] = ''
            })    
            this.formStatus = true
        }
    },
    created: function() {
        //console.log(Translation.button)
        //this.validationRequired('name.required')
    }
}
</script>
